@tailwind base;
@tailwind components;
@tailwind utilities;

.cell {
  @apply flex items-center justify-center w-5 h-5 border-2 cursor-pointer;
  border-style: outset;
  font-family: minesweeper;
}

.cell:active {
  border-style: solid;
  border-color: #8B8B8B;
  border-width: 1px;
}

.cell.toggled {
  border-style: solid;
  border-color: #8B8B8B;
  border-width: 1px;

  cursor: default;
}

.cell.toggled.bomb {
  @apply bg-red-600;
}

.cell.flagged {
  background: #000000;
  background: linear-gradient(to top, #000000 50%, #FF0000 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.controlpanel > div {
  @apply py-1 px-2 hover:bg-[#dddabd] cursor-pointer;
}

.game-button.idle:before, .game-button.started:before {
  content: '😊'
}

.game-button.won:before {
  content: '😎'
}

.game-button.dead:before {
  content: '😵'
}

.game-button:active:before {
  content: '😲'
}

.game-button {
  border-style: outset;
}

.game-button:active {
  border-style: inset;
}
